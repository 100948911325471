import { ConfirmationDialog } from '@/components/confirmation-dialog';
import RHFCombobox from '@/components/form/rhf-combobox';
import RHFSelect from '@/components/form/rhf-select';
import RHFTextArea from '@/components/form/rhf-text-area';
import RHFTextInput from '@/components/form/rhf-text-input';
import LoadingSpinner from '@/components/loading-spinner';
import Heading from '@/components/typography/heading';
import {
  TableAmbienceOptions,
  TableLocationOptions,
  TablePrivacyOptions,
  TableTypeOptions,
  TableViewOptions,
} from '@/config/dropdown-options';
import { useMatterportTags } from '@/hooks/useMatterportTags';
import { useRestaurantBySlug } from '@/hooks/useRestaurantBySlug';
import { useTableCreate } from '@/hooks/useTableCreate';
import { zodResolver } from '@hookform/resolvers/zod';
import { AmbienceAttribute, PrivacyAttribute, TableLocation, TableType, ViewAttribute } from '@repo/types';
import { Button, ScrollArea } from '@ui/components';
import { X } from 'lucide-react';
import { FormProvider, useForm } from 'react-hook-form';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

const formSchema = z
  .object({
    name: z.string().min(1, {message: 'Name is required'}),
    model: z.string(),
    internalName: z.string(),
    label: z.nativeEnum(TableType).or(z.string().max(25)).optional(), // table type ie. table, cabana, pool bed
    location: z.nativeEnum(TableLocation).or(z.string().max(25)).optional(),
    minGuests: z.number().min(1).optional(),
    maxGuests: z.number().min(1).optional(),
    clusterSize: z.number().optional(),
    attributes: z
      .object({
        ambiance: z.array(z.nativeEnum(AmbienceAttribute).or(z.string().max(25))).optional(),
        view: z.array(z.nativeEnum(ViewAttribute).or(z.string().max(25))).optional(),
        spacial: z.array(z.nativeEnum(PrivacyAttribute).or(z.string().max(25))).optional(),
      })
      .optional(),
    description: z.string().optional(),
  })
  .superRefine((values, context) => {
    if (!!values.minGuests && !!values.maxGuests) {
      if (values.minGuests > values.maxGuests) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Must be less than max',
          path: ['minGuests'],
        });
      }
      if (values.maxGuests < values.minGuests) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Must be more than min',
          path: ['maxGuests'],
        });
      }
    }
  });

export default function CreateNewPin() {
  const params = useParams();
  const navigate = useNavigate();

  if (!params.restaurantSlug) {
    throw Error(`CreateNewPin requires a restaurantSlug url param`);
  }

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      model: '',
      internalName: '',
      label: TableType.TABLE,
      location: TableLocation.INDOORS,
      minGuests: 1,
      maxGuests: 1,
      clusterSize: 0,
      attributes: {
        ambiance: [],
        view: [],
        spacial: [],
      },
      description: '',
    },
  });

  // Block navigating elsewhere when form has unsaved changes
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
        !form.formState.isSubmitting &&
        form.formState.isDirty &&
        currentLocation.pathname !== nextLocation.pathname
    );
  });


  const watchMinGuests = form.watch('minGuests');
  const watchMaxGuests = form.watch('maxGuests');

  const restaurantQuery = useRestaurantBySlug(params.restaurantSlug);
  const matterportTagsQuery = useMatterportTags(form.watch('model'));
  const createTableMutation = useTableCreate(restaurantQuery.data?.id || '');

  const modelOptions =
    restaurantQuery.data?.layouts
      ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
      .map((layout) => ({
        label: `${layout.displayName} (${layout.model})`,
        value: layout.model,
      })) || [];

  const internalNameOptions =
    matterportTagsQuery.data
      ?.map((tag: { label: string }) => ({
        label: tag.label,
        value: tag.label,
      }))
      .sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label)) || [];

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const result = await createTableMutation.mutateAsync({
      restaurantId: restaurantQuery.data?.id,
      ...values,
    });

    if (result.id) {
      form.reset()
      const newPath = `/${params.restaurantSlug}/matterport-pins/${result.id}`;
      navigate(newPath);
    }
  };

  const handleClose = () => {
    // TODO: check if form state is dirty
    const newPath = `/${params.restaurantSlug}/matterport-pins`;
    navigate(newPath);
  };

  const isModelSelected = form.watch('model').length > 0;

  return (
    <>
      <div className="flex flex-col h-full">
        <header className="flex justify-between px-5 py-3 border-b">
          <Heading>Create New Pin</Heading>
          <button onClick={handleClose}>
            <X />
          </button>
        </header>

        <FormProvider {...form}>
          <ScrollArea className="flex-1">
            <form id="create-pin-form" onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 px-5 py-3">
                <RHFSelect
                  options={modelOptions}
                  form={form}
                  name="model"
                  label="Model"
                  placeholder="Select a model.."
                />

                {matterportTagsQuery.isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <RHFCombobox
                    form={form}
                    name="internalName"
                    label="Internal Name"
                    options={internalNameOptions}
                    disabled={!isModelSelected}
                  />
                )}
                <RHFTextInput disabled={!isModelSelected} label="Display Name" form={form} name="name" />

                <RHFTextArea form={form} name="description" label="Description" textareaProps={{ rows: 8 }} />

                <div className="grid grid-cols-2 gap-2">
                  <RHFCombobox
                    form={form}
                    name="label"
                    label="Type"
                    options={TableTypeOptions}
                    allowCustomValues
                  />
                  <RHFCombobox form={form} name="location" label="Location" options={TableLocationOptions} />
                </div>

                <div className="grid grid-cols-3 space-x-4">
                  <RHFTextInput
                    form={form}
                    name="minGuests"
                    label="Min Capacity"
                    inputProps={{ type: 'number', min: 1, max: watchMaxGuests }}
                  />
                  <RHFTextInput
                    form={form}
                    name="maxGuests"
                    label="Max Capacity"
                    inputProps={{ type: 'number', min: watchMinGuests }}
                  />
                  <RHFTextInput
                    form={form}
                    name="clusterSize"
                    label="Cluster Size"
                    inputProps={{ type: 'number', min: 0 }}
                  />
                </div>

                <RHFCombobox
                  form={form}
                  name="attributes.ambiance"
                  label="Ambience"
                  options={TableAmbienceOptions}
                  isMulti
                  allowCustomValues
                />
                <RHFCombobox
                  form={form}
                  name="attributes.view"
                  label="View"
                  options={TableViewOptions}
                  isMulti
                  allowCustomValues
                />
                <RHFCombobox
                  form={form}
                  name="attributes.spacial"
                  label="Privacy"
                  options={TablePrivacyOptions}
                  isMulti
                  allowCustomValues
                />
              </div>
            </form>
          </ScrollArea>
        </FormProvider>

        <footer className="p-3 border-t-[1px]">
          <Button
            form="create-pin-form"
            type="submit"
            className="w-full"
            disabled={!form.formState.isDirty || form.formState.isSubmitting}
          >
            {form.formState.isSubmitting ? <LoadingSpinner /> : 'Confirm'}
          </Button>
        </footer>
      </div>

      {blocker.state === 'blocked' ? (
        <ConfirmationDialog
          open={blocker.state === 'blocked'}
          title="You have unsaved changes!"
          description={`Are you sure you want to leave? Your changes will not be saved.`}
          cancelLabel="Cancel"
          continueLabel="Discard Changes"
          onContinue={() => blocker.proceed()}
          onCancel={() => blocker.reset()}
        />
      ) : null}
    </>
  );
}
