import { policyUpdateFail } from '@/components/toasts';
import EventService from '@/services/event.service';
import { Event, UpdateEventRequest } from '@repo/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@ui/components';

const useEventUpdate = (restaurantId: string) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  return useMutation<Event, Error, { data: UpdateEventRequest }>({
    mutationKey: ['updateEvent', restaurantId],
    mutationFn: ({ data }) => EventService.updateRestaurantEvent(restaurantId, data),
    onSuccess: (data) => {
      toast({
        variant: 'success',
        title: `'${data.displays[0]?.title || 'New'}' event successfully updated`,
      });
      return queryClient.invalidateQueries({ queryKey: ['events', restaurantId] });
    },
    onError: (error) => {
      console.error(error);
      toast(policyUpdateFail('Failed to update event'));
    },
  });
};

export { useEventUpdate };
